<template>
  <div class="home">
    <section class="hero is-medium jumbo">
      <div class="hero-body has-text-centered">
        <img src="../assets/logo-white.svg" class="jumbo-logo">
        <div class="selector">
          <p class="heading has-text-white">Explore firms for your internship</p>
          <br>
          <div class="columns is-centered title is-5">
            <div class="column is-9-desktop">
              <div class="cat-selector columns is-multiline is-centered is-vcentered is-mobile heading is-size-6">
                <div class="column is-one-quarter-tablet is-half-mobile">
                  <router-link to="/list/all">All Firms ➔</router-link>
                </div>
                <div class="column is-one-quarter-tablet is-half-mobile">
                  <router-link to="/private-firms">Private Firms ➔</router-link>
                </div>
                <div class="column is-one-quarter-tablet is-half-mobile">
                  <router-link to="/list/ngo">NGOs ➔</router-link>
                </div>
                <div class="column is-one-quarter-tablet is-half-mobile">
                  <router-link to="/list/gov">Government Agencies ➔</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-content has-text-centered firm-logo-wall">
      <FirmLogoWall></FirmLogoWall>
    </section>
    <section class="home-content has-text-centered">
      <sponsors-list></sponsors-list>
    </section>
  </div>
</template>

<script>
import SponsorsList from "../components/SponsorsList";
import FirmLogoWall from "../components/FirmLogoWall";
export default {
  name: 'Home',
  components: {FirmLogoWall, SponsorsList},
  title: 'Sibol: Ateneo Law School Career Fair 2024'
}
</script>

<style scoped>
.jumbo {
  background: rgb(49,131,190);
  background: radial-gradient(circle, rgba(49,131,190,1) 0%, rgba(242,188,30,1) 20%, rgba(218,37,101,1) 35%, rgba(44,179,243,1) 100%);
}

.jumbo-logo {
  margin-top: 24px;
}

.selector {
  padding-top: 54px;
}

@media only screen and (max-width: 768px) {
  .jumbo-logo {
    margin-top: 124px;
  }
}

@media only screen and (min-width: 769px) {
  .jumbo-logo {
    padding-left: 15%;
    padding-right: 15%;
  }
}

.cat-selector {
  border-radius: 8px;
  background: #ffffff;
}

.home-content {
  padding: 4.2%;
}
</style>
